<template>
  <div
    class="solution-card"
    :class="{active : isActive}"
  >
    <div
      class="solution-card-icon-background"
      :class="className"
    >
      <img
        :src="src"
        :alt="title"
      >
    </div>
    <h2 class="solution-card-titile">
      {{ title }}
    </h2>
    <span class="solution-card-description">{{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  name: 'SolutionsCard',
  props: {
    className: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .solution-card {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    padding: 0.32rem 0.2rem;
    border-radius: 8px;
    cursor: pointer;

    &:hover,
    &.active {
      background: $background-white;
      box-shadow: 0px 10px 18px rgba(217, 202, 255, 0.4);
    }

    .solution-card-icon-background {
      width: .6rem;
      height: .6rem;
      margin-bottom: .37rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: .2rem;
      img {
        max-width: .6rem;
        max-height: .6rem;
      }
    }

    .solution-card-titile {
      font-weight: 800;
      font-size: .18rem;
      color: $text-primary;
      margin-bottom: .12rem;
      line-height: .24rem;
    }

    .solution-card-description {
      font-weight: 500;
      font-size: 0.14rem;
      color: $text-secondary;
      margin-bottom: 0.04rem;
      line-height: .21rem;

      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
</style>
