<template>
  <div id="solutions-page">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <why-choose-us login-variant="header" />
    <section class="other">
      <div class="other__solutions">
        <solutions-card
          v-for="(solutionsCard, index) in otherSolutions"
          :key="index"
          :is-active="index===activeSolution"
          v-bind="solutionsCard"
          @click.native="activeSolution = index"
        />
      </div>
      <div class="solution-details flex-column">
        <p class="solution-details__label">
          Other Solutions
        </p>
        <h5 class="solution-details__title">
          {{ otherSolutions[activeSolution].title }}
        </h5>
        <h6 class="solution-details__subtitle">
          {{ otherSolutions[activeSolution].subtitle }}
        </h6>
        <p class="solution-details__description">
          {{ otherSolutions[activeSolution].description }}
        </p>
        <router-link
          :to="otherSolutions[activeSolution].readMoreLink"
          tag="el-button"
          class="more-details"
        >
          {{ $t(`button['More Details']`) }}
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import SolutionsCard from '@/views/pages/Solutions/components/SolutionsCard/Index.vue';
import Financial from '@/assets/images/icons/financial.svg';
import education from '@/assets/images/icons/education.svg';
import media from '@/assets/images/icons/media.svg';
import WhyChooseUs from '@/views/components/WhyChooseUs/Index.vue';

export default {
  name: 'Solutions',
  components: {
    SolutionsCard,
    WhyChooseUs,
  },
  data() {
    return {
      activeSolution: 0,
      solutionsCards: [
        {
          src: Financial,
          className: 'card-financial',
          title: this.$t("solutions['financial services']"),
          subtitle: this.$t('solutions.financialServices.subtitle'),
          description: this.$t('solutions.financialServices.description'),
          readMoreLink: '/solutions/financial',
        },
        {
          src: media,
          className: 'card-media',
          title: this.$t("solutions['media and entertainment']"),
          subtitle: this.$t('solutions.mediaAndEntertainment.subtitle'),
          description: this.$t('solutions.mediaAndEntertainment.description'),
          readMoreLink: '/solutions/media',
        },
        {
          src: education,
          className: 'card-education',
          title: this.$t('solutions.education'),
          subtitle: this.$t('solutions.educationService.subtitle'),
          description: this.$t('solutions.educationService.description'),
          readMoreLink: '/solutions/education',
        },
      ],
    };
  },
  computed: {
    otherSolutions() {
      return this.solutionsCards.filter(({ readMoreLink }) => this.$route.path !== readMoreLink);
    },
  },
};
</script>

<style lang="scss" scoped>
  #solutions-page {
    background-color: $background-secondary;

    @include tablet-and-less {
      /deep/ .header .content {
        background-image: none;
      }
    }
  }

  .other {
    display: flex;
    padding: .38rem .86rem 1.43rem .83rem;

    @include tablet-and-less {
      flex-direction: column;
    }

    &__solutions {
      width: 50%;
      display: grid;
      grid-template: repeat(4,1fr) / repeat(2, 1fr);
      grid-gap: .2rem;
      padding: .23rem;
      background: url('~@/assets/images/icons/solutions/bg-other-solution.svg') center / contain no-repeat;

      @include tablet-and-less {
        width: 100%;
      }

      .solution-card {
        &:first-child {
          grid-row: 2 / 4;
        }

        &:nth-child(2) {
          grid-area: 1 / 2 / 3;
        }

        &:nth-child(3) {
          grid-area: 3 / 2 / 5;
        }
      }
    }

    /deep/ .card {
      &-financial {
        background-color: #FC9C0D;
        box-shadow: 4px 7px 17px rgba(252, 156, 13, 0.3);
      }
      &-media {
        background-color: #64ddec;
        box-shadow: 4px 7px 17px rgba(100, 221, 236, 0.42);
      }
      &-health {
        background-color: #3a71ff;
        box-shadow: 4px 7px 17px rgba(58, 113, 255, 0.42);
      }
      &-education {
        background-color: #ac46ff;
        box-shadow: 4px 7px 17px rgba(172, 70, 255, 0.42);
      }
    }

    .solution-details {
      flex: 1;
    }
  }

  .solution-details {
    align-items: flex-start;
    padding-left: 1rem;

    @include tablet-and-less {
      padding-left: 0;
    }

    &__label {
      font-size: 0.18rem;
      line-height: .22rem;
      font-weight: 700;
      color: $text-blue;
      margin-bottom: .12rem;
    }

    &__title {
      font-weight: 800;
      font-size: .32rem;
      line-height: .43rem;
      margin-bottom: .14rem;
    }

    &__subtitle,
    &__description {
      font-size: .14rem;
      line-height: .19rem;
      color: $text-tertiary;
      margin-bottom: .32rem;
    }

    &__subtitle {
      font-weight: 700;
    }

    &__description {
      font-weight: 400;
    }

    .more-details {
      background: linear-gradient(100.62deg, #521FD1 48.27%, rgba(121, 58, 255, 0) 203.77%);
      font-weight: 600;
      font-size: .14rem;
      line-height: .17rem;
      color: white;
    }

  }
</style>
